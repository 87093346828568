export const initialValues = {
  modal: null,
  modalProps: null,
};

export const Types = {
  OPEN: "OPEN_MODAL",
  CLOSE: "OPEN_MODAL",
};

export const reducer = (state, action) => {
  switch (action.type) {
    case Types.OPEN:
      return {
        ...state,
        modal: action.modal,
        modalProps: action.modalProps,
      };

    case Types.CLOSE:
      return initialValues;

    default:
      return state;
  }
};

export const Actions = {
  open: ({ modal, size = 300, props }) => ({
    type: Types.OPEN,
    modal,
    size,
    modalProps: props,
  }),
  close: () => ({ type: Types.CLOSE }),
};

export const ActionModals = {
  openModalWebsiteLocked: ({ days }) =>
    Actions.open({
      modal: "ModalWebsiteLocked",
      props: { days },
    }),
  openModalWebsiteUnlocked: ({ dateEnd, urlSite }) =>
    Actions.open({
      modal: "ModalWebsiteLocked",
      props: { dateEnd, urlSite },
    }),
  openModalFormCallYou: ({ onSubmitSuccess }) =>
    Actions.open({
      modal: "ModalFormCallYou",
      props: { onSubmitSuccess },
    }),
  openModalEmergency: () =>
    Actions.open({
      modal: "ModalEmergency",
      props: {
        size: 460
      },
    }),
  openModalEvent: () =>
    Actions.open({
      modal: "ModalEvent",
      props: {
        size: 890,
      },
    }),
  openModalSignup: () =>
    Actions.open({
      modal: "ModalSignup",
      props: {
        size: 400,
      },
    }),
  openModalPortalConditions: () =>
    Actions.open({
      modal: "ModalPortalConditions",
      props: {
        size: 1190,
      },
    }),
};
